import { createStore } from 'vuex';

import identity from './identity';
import authentication from './authentication';
import inventions from './inventions';
import stream from './stream';
import ifw_stream from './ifw-stream';
import messages from './messages';
import tasks from './tasks';
import applications from './applications';
import boards from './boards';
import filters from './filters';
import reports from './reports/';
import steps from './steps';
import milestones from './milestones';
import workflows from './workflows';
import emails from './emails';
import docketing from './docketing';
import milestoneNotes from './milestoneNotes';
import directoryIndex from './directoryIndex';
import httpClient from '@/utils/httpClient';

export default (initialState = {}) =>
  createStore({
    modules: {
      identity,
      authentication,
      inventions,
      stream,
      ifw_stream,
      messages,
      tasks,
      applications,
      boards,
      filters,
      reports,
      steps,
      milestones,
      workflows,
      emails,
      docketing,
      milestoneNotes,
      directoryIndex
    },
    state: { ...initialState, bots: [] },
    mutations: {
      BOTS_LIST_FETCHED(state, list) {
        state.bots = list;
      }
    },
    actions: {
      async EXECUTE_BOTS_UPDATE({ commit, dispatch }) {
        try {
          const list = await httpClient.get(`/api/auth/bots`);

          const scriptExistsPromises = list.map(({ email }) => {
            return httpClient.get(`/api/auth/bots/${email}/script/exists`);
          });
          const existArr = await Promise.all(scriptExistsPromises);

          const bots = list.filter((b, index) => existArr[index].exists).map(b => b.email);

          commit('BOTS_LIST_FETCHED', bots);
        } catch (e) {}

        setTimeout(async () => {
          await dispatch('EXECUTE_BOTS_UPDATE');
        }, 5 * 60 * 1000);
      }
    }
  });
