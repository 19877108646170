<template>
  <div class="multiselect-wrapper" :class="{ 'has-label': !!label }">
    <label v-if="!!label" :for="id">{{ label }} </label>
    <div :class="{ disabled }">
      <vue-multiselect
        :id="id"
        ref="multiselect"
        v-model="modelValue"
        :close-on-select="closeOnSelect"
        :can-deselect="canDeselect"
        :options="searchChangeCallback ? load : sortedOptions"
        :object="object"
        :loading="loading || busy"
        :mode="mode"
        :searchable="searchable"
        :create-tag="createNewOption"
        :append-new-tag="false"
        :delay="searchChangeCallback ? 500 : -1"
        :filter-results="filterResults"
        :resolve-on-load="true"
        :limit="limit"
        :placeholder="placeholder"
        :hide-selected="true"
        :disabled="disabled"
        :can-clear="canClear"
        :no-options-text="noOptionsText"
        :show-options="showOptions"
        @change="$emit('change', $event)"
        @update:modelValue="$emit('update:value', $event)"
        @input="$emit('input', $event)"
        @tag="$emit('tag', $event)"
      >
        <template #tag="{ option, handleTagRemove }">
          <div class="multiselect-tag is-user">
            <div v-if="!disabled && tagOpen && tagOpen.action" class="tag-open" :title="tagOpen?.title" @click.prevent="tagOpen?.action(option)">
              <Icon :name="option.executing ? 'loading' : tagOpen?.icon" size="sm" :spin="option.executing" />
            </div>
            <span class="tag-label">{{ option.label }}</span>
            <span class="multiselect-tag-remove">
              <i v-if="!disabled" class="multiselect-tag-remove-icon" @mousedown.prevent="handleTagRemove(option, $event)"></i>
            </span>
          </div>
        </template>
        <template #option="{ option }">
          <span class="option-label">{{ option.label }} </span></template
        >
      </vue-multiselect>
    </div>
  </div>
</template>
<script>
import Multiselect from '@vueform/multiselect';
import Icon from '@/components/common/Icon';

export default {
  components: { 'vue-multiselect': Multiselect, Icon },
  props: {
    id: {
      type: String,
      default: () => (+new Date()).toString()
    },
    value: {
      type: [String, Array, Object],
      default: () => null
    },
    options: {
      type: Array,
      default: () => []
    },
    object: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: true
    },
    taggable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Search or add a tag'
    },
    tagPlaceholder: {
      type: String,
      default: 'Press Enter to create a tag'
    },
    searchChangeCallback: {
      type: Function,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    canDeselect: {
      type: Boolean,
      default: true
    },
    createOption: {
      type: Boolean,
      default: undefined
    },
    filterResults: {
      type: Boolean,
      default: true
    },
    canClear: {
      type: Boolean,
      default: true
    },
    limit: {
      type: Number,
      default: 20
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    noOptionsText: {
      type: String,
      required: false,
      default: 'The list is empty'
    },
    tagOpen: {
      type: Object,
      default: () => null
    },
    busy: {
      type: Boolean,
      default: false
    },
    showOptions: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:value', 'change', 'tag', 'input'],
  data() {
    return {
      modelValue: this.value,
      loading: false
    };
  },
  computed: {
    mode() {
      if (this.taggable) {
        return 'tags';
      }
      if (this.multiple) {
        return 'multiple';
      }

      return 'single';
    },
    createNewOption() {
      if (this.createOption !== undefined) {
        return this.createOption;
      }

      return this.taggable;
    },
    sortedOptions() {
      return [...this.options].sort((a, b) => (a.label <= b.label ? -1 : 1));
    }
  },
  watch: {
    value: {
      handler() {
        this.modelValue = this.value;
        this.load();
      }
    }
  },
  methods: {
    selectAll() {
      if (this.mode === 'single') {
        return;
      }

      const filtered = this.$refs.multiselect.filteredOptions;

      if (!filtered?.length) {
        return;
      }

      this.$refs.multiselect.selectAll();

      this.$toast.success(
        {
          message: `${filtered.length} items selected`
        },
        5000
      );
    },
    async load(query = '') {
      if (!this.searchChangeCallback) {
        return this.options;
      }
      this.loading = true;
      const options = await this.searchChangeCallback(query).then(response => {
        this.loading = false;
        return response;
      });

      if (this.object) {
        return options;
      }

      return Array.from(new Set([...(Array.isArray(this.value) ? this.value : this.value ? [this.value] : []), ...options]))
        .map(s => ({
          value: s,
          label: s
        }))
        .sort((a, b) => (a.label <= b.label ? -1 : 1));
    }
  }
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
:root {
  --ms-px: 0.875rem;
}

.multiselect {
  background: var(--theme-background);
  border-radius: 2px;
  border: 1px solid var(--theme-on-background-accent);
  min-height: 33px;

  &.is-active {
    border: var(--ms-border-width, 1px) solid var(--theme-on-surface);
    box-shadow: 0 0 0 var(--ms-ring-width, 3px) var(--ms-ring-color, transparent);
  }
}

.multiselect-multiple-label,
.multiselect-single-label,
.multiselect-placeholder {
  background: transparent;
  font-size: 0.9rem;
  color: var(--theme-on-background-accent);
}

.multiselect-placeholder {
  color: var(--theme-on-background-accent);
}

.multiselect-search {
  background: var(--theme-background);
}

.multiselect-tags-search {
  background: var(--theme-background);
  caret-color: var(--theme-on-background-accent);
  color: var(--theme-on-background-accent);
}
.multiselect-tag {
  display: flex;
  align-items: center;
  cursor: default;
}

.multiselect-clear {
  align-self: flex-start;
  margin-top: 7px;
  margin-left: 5px;
  z-index: 5;
}

.multiselect-caret {
  z-index: 0;
  align-self: flex-start;
  margin-top: 7px;
}

.multiselect-dropdown {
  background: var(--theme-background);
}
.tag-label {
  overflow: hidden;
  word-wrap: break-word;
  white-space: pre-wrap;
  line-height: 1rem;
  padding: 2px;
}
.tag-open {
  padding-right: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.multiselect-tag-remove-icon {
  cursor: pointer;
}
.multiselect-wrapper {
  display: grid;
  grid-template-rows: max-content;
  grid-gap: 5px;

  &.has-label {
    grid-template-rows: max-content max-content;
  }

  > label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
  }
}
</style>
