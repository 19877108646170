export default [
  {
    path: '/',
    name: 'home',
    component: () => import('./../views/MyInventionsReport')
  },
  {
    path: '/reports/tasks',
    name: 'report-tasks',
    component: () => import('./../views/TasksReport')
  },
  {
    path: '/inventions',
    name: 'inventions',
    component: () => import('./../views/Inventions')
  },
  {
    path: '/inventions/my',
    name: 'my inventions',
    component: () => import('./../views/MyInventionsReport')
  },
  {
    path: '/inventions/:id',
    name: 'invention',
    component: () => import('./../views/Inventions'),
    children: [
      { path: '', component: () => import('../components/inventions/pages/Tasks') },
      { path: 'stream', component: () => import('../components/inventions/pages/Stream') },
      { path: 'ifw', component: () => import('../components/inventions/pages/IFW') },
      { path: 'messages', component: () => import('../components/inventions/pages/Messages') },
      { path: 'emails', component: () => import('../components/inventions/pages/Emails') },
      { path: 'docketing', component: () => import('../components/inventions/pages/Docketing') }
    ]
  },
  {
    path: '/boards',
    name: 'boards',
    component: () => import('./../views/Boards')
  },
  {
    path: '/boards/:id',
    name: 'board',
    component: () => import('./../views/Board')
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import('./../views/Tasks')
  },
  {
    path: '/tasks/:id',
    name: 'task',
    component: () => import('./../views/Tasks')
  },
  {
    path: '/applications',
    name: 'applications',
    component: () => import('./../views/Applications')
  }
];
